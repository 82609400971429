






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import CartItemCoreModel from '@/modules/carts/models/CartItemCoreModel';
import DataTable from '@/shared/resources/components/tables/data-table/DataTable.vue';
import ActionsButton from '@/shared/resources/components/tables/data-table/actions/ActionsButton.vue';
import TableTemplateInterface from '@/shared/lib/interfaces/TableTemplateInterface';
import Cart from '@/app/lib/cart/Cart';
import ImgViewer from '@/shared/resources/components/ImgViewer.vue';
import CartItemsTableQuantityField from '@/app/modules/cart/components/partials/tables/CartItemsTableQuantityField.vue';
import UISettings from '@/app/lib/settings/UISettings';

@Component({
  components: {
    CartItemsTableQuantityField,
    ImgViewer,
    ActionsButton,
    DataTable,
  },
})
export default class CartItemsTable extends Vue {
  /**
   * Props
   */
  @Prop() private items!: CartItemCoreModel[];

  /**
   * Data
   */
  private thumbSize: number = 60;
  private loading: any = {};

  private template: TableTemplateInterface = {
    search: false,
  };

  private headers: DataTableHeader[] = [
    {
      value: 'product',
      text: 'Produkt',
    },
    {
      value: 'quantity',
      text: 'Liczba',
      width: 130,
    },
    {
      value: 'priceNet',
      text: 'Netto',
      width: 120,
    },
    {
      value: 'priceGross',
      text: 'Brutto',
      width: 120,
    },
    {
      value: 'actions',
      text: '',
      class: 'table-col-actions',
    },
  ];

  /**
   * Methods
   */
  private removeFromCart(item: CartItemCoreModel) {
    Cart.removeItem(item);
  }

  private showLoading(productId: number) {
    this.loading[productId] = true;
  }

  private hideLoading(productId: number) {
    delete this.loading[productId];
  }

  private isItemQuantityLoading(productId: number): boolean {
    return this.loading[productId];
  }

  private openDetailsModal(productId: number) {
    UISettings.openSideModal('ProductDetails', { id: productId }, 'Szczegóły produktu');
  }

  private thumbPath(path: string): string {
    return `${path}?w=${this.thumbSize * 2}&h=${this.thumbSize * 2}`;
  }

  /**
   * Handlers
   */
  private onRemoveButtonClick(item: CartItemCoreModel) {
    this.removeFromCart(item);
  }

  private onNameClick(productId: number) {
    this.openDetailsModal(productId);
  }
}
